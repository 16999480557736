.admin-content {
  width: 980px;
  margin: 48px auto;
}

.admin-content:after {
  display: table;
  height: 80px;
  width: 100%;
  clear: both;
  content: "";
}

.admin-nav {
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-nav a,
.button-text {
  cursor: pointer;
  font-weight: 800;
  font-size: 12px;
  color: #f36e0d;
  letter-spacing: 0.67px;
  text-transform: uppercase;
  display: inline-block;
}

.admin-nav .button {
  padding: 5px 10px;
  background: rgba(243, 110, 13, 0.4);
  border-radius: 5px;
  padding-bottom: 4px;
}

.admin-nav .admin-back {
  padding-left: 28px;
  background: transparent no-repeat left url("./assets/back.svg");
}

.admin-inner {
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 100px;
  margin-top: 4px;
  padding: 32px;
}

.form-row {
  display: flex;
  margin: 0 -10px;
  margin-bottom: 40px;
}

.form-row .form-input {
  margin: 0 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.admin-content label {
  display: block;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #afafaf;
  letter-spacing: 0.67px;
  padding-bottom: 6px;
}

.form-input input[type="text"],
.form-input input[type="email"],
.form-input input[type="number"],
.form-input select {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: auto;
  font-size: 14px;
  flex: 1;
  display: flex;
  outline: none;
  height: 38px;
  min-height: 38px;
  padding: 0 8px;

  &:focus {
    border-color: #aaa;
  }

  &:disabled {
    background: #fafafa;
    color: #999;
  }

  &::placeholder {
    color: #ccc;
  }
}

.admin-area-editor {
  display: flex;

  .areas-list {
    background: #fafafa;
    border-radius: 5px;
    padding: 12px 24px;
    flex-basis: 280px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .area {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.67px;
      text-transform: uppercase;
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #f1f2f4;

      &.active {
        color: #f36e0d;
        cursor: default;
      }

      div {
        color: #666;
      }

      .material-icons {
        font-size: 19px;
        margin-left: 4px;
        cursor: pointer;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .areas-map {
    background: #ceebec;
    min-height: 480px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    flex: 1;
    margin-left: 12px;
    .form-row {
      background: #fff;
      margin-bottom: 0;
      padding-bottom: 16px;
      input {
        width: 100% !important;
      }
    }
    .form-rows + div {
      height: 360px !important;
    }
  }
}

.admin-inner table {
  min-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 32px;
  border-spacing: 0;

  th {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #afafaf;
    letter-spacing: 0.67px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f2f4;
  }

  td {
    font-size: 14px;
    line-height: 19px;
    padding: 12px 0;
    border-bottom: 1px solid #f1f2f4;

    .title {
      color: #000 !important;
      font-weight: 500;
      cursor: pointer;
    }

    .material-icons {
      font-size: 19px;
      margin-right: 10px;
      cursor: pointer;
      color: #666;
      margin-top: 3px;

      &:hover {
        color: #000;
      }
    }
  }
}

.button-text.button-secondary {
  color: #afafaf;
  line-height: 19px;
  margin-bottom: 9px;

  .material-icons {
    font-size: 19px;
    float: left;
    margin-right: 6px;
  }
}

.subusers {
  margin-top: 36px;
}
