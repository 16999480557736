/* ==========================================================================
   General
   ========================================================================== */

@font-face {
  font-family: "main";
  src: local("__main"), url("./assets/fonts/main-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "main";
  src: local("__main"), url("./assets/fonts/main-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "main";
  src: local("__main"), url("./assets/fonts/main-semibold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

:root {
  --amplify-primary-color: #2e89ea;
  --amplify-primary-tint: #2e89ea;
  --amplify-primary-shade: #2e89ea;
}

html,
body,
p,
input,
textarea {
  margin: 0;
  padding: 0;
  font-family: "main", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.7;
  color: #000;
}

html,
body {
  min-height: 100%;
  background-color: transparent;
}

html {
  background: #4e67cc linear-gradient(-90deg, #594cc3 20%, #2b8aeb 100%)
    no-repeat center !important;
}

html,
body,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.container {
  border-bottom: 2px solid #f1f2f4;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 90px;
}

.app {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  height: 100vh;
}

.body {
  flex: 1;
  overflow: auto;
  background: #fafafa;
  -webkit-overflow-scrolling: touch;
  position: relative;
  display: flex;
  min-height: calc(100vh - 76px);
}

.body-content {
  position: relative;
  z-index: 100;
  flex: 1;
  display: flex;
  pointer-events: none;
  height: calc(100vh - 76px);
}

.content-inner {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
}

/* ==========================================================================
   Loading screen
   ========================================================================== */

#loader {
  background: #4e67cc linear-gradient(-90deg, #594cc3 20%, #2b8aeb 100%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease-out;
  z-index: 8000;
  display: flex;
  user-select: none;
}

#loader:after {
  flex: 1;
  content: "";
  background: transparent url("./assets/logo.svg") no-repeat center;
  background-size: 256px auto;
}

#loader.hidden {
  opacity: 0;
}

/* ==========================================================================
   Authentication
   ========================================================================== */

[data-amplify-authenticator][data-variation=modal] {
  background: transparent;
  padding-top: 0;
  align-content: center;

  --amplify-components-button-primary-background-color: #2b8aeb;
  --amplify-components-button-link-color: #2b8aeb;
  --amplify-components-button-link-hover-color: #2b8aeb;
}

[data-amplify-authenticator][data-variation=modal]::before {
  display: none;
}

.amplify-tabs {
  display: none;
}

/* ==========================================================================
   Navigation
   ========================================================================== */

nav {
  padding: 24px 36px 24px 228px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent url("./assets/logo.svg") no-repeat 32px 50%;
  user-select: none;
}

nav div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

nav a {
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 26px;
  opacity: 0.5;
  letter-spacing: 0.5px;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

nav a.active {
  opacity: 1;
}

nav a .material-icons {
  font-size: 18px;
  margin-right: 6px;
}

nav > div:last-child a {
  margin-right: 0;
  margin-left: 26px;
}

/* ==========================================================================
   Report pages
   ========================================================================== */

.report-left {
  user-select: none;
  width: 50%;
  max-width: 800px;
  min-width: 500px;
  background-image: linear-gradient(
    270deg,
    rgba(250, 250, 250, 0) 0%,
    #fafafa 60%
  );
  padding: 48px;
  flex: 1;
  display: flex;
}

.report-left .legend {
  pointer-events: initial;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 200px;

  .placeholder {
    padding: 32px 24px;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    color: #777;
  }

  a {
    display: block;
    padding: 15px 20px;
    position: relative;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
    color: #afafaf;
    line-height: 20px;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 20px;
      right: 0;
      bottom: 0;
      height: 0;
      border-bottom: 1px solid #eee;
    }

    .legend-stat {
      color: #333;
      font-size: 14px;
      font-weight: normal;
      text-transform: none;
      display: inline-block;

      .material-icons {
        font-size: 14px;
        margin-top: 3px;
        margin-right: 3px;
        float: left;
      }

      &:first-child {
        clear: left;
        margin-right: 14px;
      }
    }

    &:hover:not(.active) {
      background: #f9f9f9;
    }

    &.active {
      background: #2d89ea;
      color: #fff;
      cursor: default;

      &:after {
        display: none;
      }

      &:before {
        content: "";
        border: 8px solid transparent;
        border-right-color: #eee;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -8px;
      }

      .legend-stat,
      .legend-stat .material-icons {
        color: #fff;
      }
    }
  }
}

.report-left .detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 48px;
}

.headline {
  .timestamp-main {
    font-size: 44px;
    font-weight: 300;
    line-height: 48px;
  }

  .timestamp-label {
    color: #afafaf;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.progress {
  user-select: none;
  background: #f36e0d;
  border-radius: 10px;
  height: 10px;
  margin-top: 12px;
  margin-bottom: 6px;
  overflow: hidden;

  .bar {
    border: 3px solid #f9f9f9;
    background: #2ecd70;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    top: -3px;
    left: -3px;
    height: 10px;
    box-sizing: content-box;
    position: relative;

    &.blue {
      background: #2b8aeb;
    }
  }
}

.labels {
  display: flex;
  justify-content: space-between;

  .desc {
    font-size: 11px;
    font-weight: 800;
    padding: 2px 5px;
    display: inline-block;
    text-transform: uppercase;
    background: rgba(243, 110, 13, 0.2);
    color: #f36e0d;
    border-radius: 5px;

    &.orange {
      color: #2ecd70;
      background: rgba(46, 205, 112, 0.2);
      margin-right: 7px;
    }

    &.blue {
      background: rgba(43, 138, 235, 0.2);
      color: #2b8aeb;
      margin-right: 7px;
    }
  }
}

.cars {
  pointer-events: initial;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 238px;
  height: 316px;
  position: relative;
  display: flex;
  user-select: none;
}

.cars .car {
  flex: 1;
  overflow: hidden;
  border-radius: 10px;
  padding: 34px;
  padding-top: 44px;

  .plate {
    line-height: 38px;
    text-align: center;
    font-size: 22px;
    letter-spacing: 0.5px;
    font-weight: bold;
    background: #f4ba41;
    border-left: 16px solid #2155d6;
    border-radius: 6px;
    margin-bottom: 6px;
  }

  .stat {
    font-size: 24px;
    font-weight: 300;
    margin-top: 20px;
    line-height: 1.2;

    span {
      display: block;
      color: #afafaf;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 900;
    }
  }

  img {
    position: absolute;
    left: 50%;
    margin-left: -48px;
    width: 96px;
    margin-top: -36px;
    top: 0;
  }

  .graph {
    display: flex;
    margin-top: 24px;
    margin-right: -20px;
    align-items: center;
  }

  .graph-left {
    display: flex;
    width: 44px;
    height: 52px;
    align-items: flex-end;
    margin-right: 10px;
  }

  .graph-left .bar {
    background: #eee;
    border-radius: 3px;
    width: 12px;
    margin-right: 3px;
    min-height: 4px;
  }

  .graph-left .bar.green {
    background: #2ecd70;
  }

  .graph-left .bar.orange {
    background: #f36e0d;
  }

  .graph-left .bar.blue {
    background: #1851de;
  }

  .graph-legend {
    font-size: 12px;
    line-height: 1.6;
  }

  .graph-legend .green {
    color: #2ecd70;
  }

  .graph-legend .orange {
    color: #f36e0d;
  }

  .graph-legend .blue {
    color: #1851de;
  }
}

.cars-button {
  position: absolute;
  top: 47px;
  background: transparent url("./assets/button-left.svg") no-repeat center;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  cursor: pointer;

  &.prev {
    left: -16px;
  }

  &.next {
    right: -16px;
    background-image: url("./assets/button-right.svg");
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.download-pdf {
  position: absolute;
  right: 28px;
  top: 24px;
  font-size: 12px;
  font-weight: 800;
  color: #5a6567;
  padding: 5px 10px;
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  background: rgba(90, 101, 103, 0.4);
  pointer-events: initial;
  border-radius: 5px;
}

.body-content.monthly {
  pointer-events: initial;
}

.body-content.monthly .report-left {
  width: auto;
  flex: 1;
  max-width: none;
  min-width: 0;
}

.body-content.monthly .report-left .detail {
  flex: 1;
}

.body-content.monthly .report-left .detail .headline {
  max-width: 360px;
  margin-bottom: 40px;
}

.report-cards {
  flex: 1;
  margin: -20px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  padding: 20px;
}

.report-left .detail .report-cards .cars {
  width: 300px;
  height: 100%;
  overflow: hidden;
  margin-right: 24px;
}

.report-left .detail .report-cards .car .stat img {
  position: static;
  margin: 0;
  height: 32px;
  width: auto;
  margin-right: 5px;
}

.report-left .detail .cars .car .graph-left .bar {
  min-height: 3px;
}

.report-cards h3 {
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  color: #2d89ea;
}

.car-map {
  margin: -34px;
  margin-top: -44px;
  height: 160px;
  margin-bottom: 20px;
  overflow: hidden;
}

.car-map > div {
  height: 200px !important;
  width: 370px;
}

.info-window-times {
  font-size: 20px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 8px;
  text-align: center;
}

.info-window-details {
  color: #afafaf;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
}

.info-window-details a {
  font-size: 9px;
  margin-top: 10px;
  display: block;
  color: #448ae3;
}

.report-left .detail .cars .car .stat span.plus-more {
  line-height: 32px;
  float: right;
  font-size: 13px;
  font-weight: 600;
  color: #000;
  margin-left: 3px;
  border-radius: 20px;
  background: #f2f2f2;
  min-width: 32px;
  text-align: center;
}

.impersonate-bar {
  background-color: #f26e0e;
  color: #fff;
  padding: 0.7rem 2rem;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: 0;
    color: #f26e0e;
    background: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    padding: 7px 14px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  html {
    background-image: none !important;
  }
}}
