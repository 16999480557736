.dropdown {
  position: relative;
}

.dropdown-caret {
  margin-left: 4px;
  transform: rotate(0deg) translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.dropdown.active .dropdown-caret {
  transform: rotateX(-180deg) translateY(-2px);
}

.dropdown.active .dropdown-label {
  opacity: 1;
}

.dropdown-content {
  position: absolute;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px 20px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 10px;
  top: 0;
  opacity: 0;
  min-width: 200px;
  align-items: flex-start;
  text-align: left;
  pointer-events: none;
  z-index: 1000;
  flex-direction: column;
}

.dropdown.active .dropdown-content {
  top: 64px;
  opacity: 1;
  pointer-events: initial;
}

.dropdown-content ul,
.dropdown-content li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dropdown-content a {
  color: #000;
  margin: 0 !important;
}

.dropdown-content a:hover {
  opacity: 1;
}

.dropdown-account .dropdown-content {
  width: 200px;
  left: auto;
  right: -20px;
  transform: none;
  align-items: flex-start;
}

.dropdown-account .down {
  margin-top: 16px;
}

.dropdown-account .checkboxes li a {
  cursor: pointer;
}

.dropdown-account .checkboxes li:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #ddd;
  display: inline-block;
  float: left;
  margin-top: 8px;
  margin-right: 6px;
  border-radius: 6px;
}

.dropdown-account .checkboxes li:hover:not(.active):before {
  background: #000;
}

.dropdown-account .checkboxes li.active a {
  opacity: 1;
  color: #347ee3;
}

.dropdown-account .checkboxes li.active:before {
  background: #347ee3;
}
